import React from "react";
import '../../styles/Implantologia.scss';
import TitleHeader from "../../components/TitleHeader";
import Layout from "../../components/layout"


const Implantologia = () => {
  return (
    <Layout>
      <TitleHeader title="Implantologia"/>
      
      <div className="grid-two-column content">
        <h2 className="first-column">bezpieczna korekta</h2>
        <p className="second-column">
          Implantologia przychodzi z pomocą, zapewniając bezpieczną alternatywę
          dla protez i mostów. Technika wprowadzania implantów to profesjonalny
          i potwierdzony w swojej skuteczności zabieg. Gwarancja swobody
          jedzenia, mówienia z właściwą dykcją, brak ryzyka narażania sąsiednich
          zębów.
        </p>
        <h2 className="first-column">korzyści dla Ciebie</h2>
        <p className="second-column">
          Specjaliści Dental Arts Studio Kraków wprowadzają implanty z
          wykorzystaniem najnowszego sprzętu i materiałów stomatologicznych
          wyłącznie klasy premium. Piękny uśmiech – gwarantowany. Poczucie
          własnych, naturalnych zębów, bez dyskomfortu -zapewnione. Profilaktyka
          zaniku kości. Zabieg o stałym charakterze i długiej satysfakcjonującej
          eksploatacji.
        </p>
        <h2 className="first-column">zalecenia</h2>
        {/* <p className="second-column"> */}
          <table id="tabelka-zalecen" className="tabelka">
            <thead>
              <tr>
                <td colSpan="2">
                  <h3>
                    Dla powodzenia zabiegu implantacji/odbudowy kości koniecznie
                    powinieneś:
                  </h3>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/bandage.png" alt="bandaż"/>
                </td>
                <td>Usunąć z ust sterylny gazik dopiero 30 min po zabiegu</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/medicine.png" alt="leki"/>
                </td>
                <td>Przyjmować leki zgodnie z zaleceniami</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/toothbrush.png" alt="szczotkować"/>
                </td>
                <td>
                  Dbać o higienę jamy ustnej używając bardzo miękkiej
                  szczoteczki oraz płynu antyseptycznego
                </td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/contact.png" alt="skontaktować się"/>
                </td>
                <td>Skontaktować się z nami gdy coś Cię niepokoi</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/cold.png" alt="okłady"/>
                </td>
                <td>Stosować chłodne okłady na operowaną okolicę</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/cold-drink.png" alt="zimne napoje"/>
                </td>
                <td>
                  Pić tylko chłodne niegazowane napoje przez 48h po zabiegu
                </td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/cold-eat.png" alt="zimne jedzenie"/>
                </td>
                <td>
                  Jeść tylko chłodne, miękkie, półpłynne lekko doprawione
                  potrawy przez 48 h po zabiegu
                </td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/check.png" alt="wizyta kontrolna"/>
                </td>
                <td>Przyjść na wizytę kontrolną</td>
              </tr>
            </tbody>
            <thead>
              <tr>
                <td colSpan="2">
                  <h3>
                    Dla właściwego gojenia po zabiegu implantacji/odbudowy kości
                    nie wolno:
                  </h3>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-hot-drink.png" alt="ciepłe napoje"/>
                </td>
                <td>Pić gorących, gazowanych napojów przez 48 h</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-eat.png" alt="nie jedz"/>
                </td>
                <td>Jeść przez 2 h po zabiegu</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-smoke.png" alt="nie pal"/>
                </td>
                <td>
                  Palić tytoniu, papierosów elektronicznych przez 48 h po
                  zabiegu
                </td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-spicy.png" alt="nie jedz ostrego"/>
                </td>
                <td>
                  Jeść gorących, mocno przyprawionych potraw oraz takich które
                  wymagają mocnego gryzienia oraz przeżuwania przez 48 h po
                  zabiegu
                </td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-drink.png" alt="nie pij alkoholu"/>
                </td>
                <td>Pić alkoholu przez 7 dni po zabiegu</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-gym.png" alt="nie ćwicz"/>
                </td>
                <td>Ograniczyć wysiłek fizyczny do minimum przez 7 dni</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-spa.png" alt="nie korzystaj z sauny"/>
                </td>
                <td>Nie korzystać z sauny, solarium przez 7 dni</td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-tooth.png" alt="nie dotykaj językiem"/>
                </td>
                <td>
                  Nie „sprawdzać” okolicy zabiegu językiem lub odchylając wargę,
                  policzek
                </td>
              </tr>
              <tr>
                <td>
                  <img src="http://sztukastomatologii.pl/wp-content/uploads/2019/03/dont-pulse.png" alt="unikaj aktywności"/>
                </td>
                <td>
                  W przypadku zabiegu podniesienia dna zatoki szczękowej przez 7
                  dni unikać aktywności które wiążą się ze zmianą ciśnienia np.
                  lotu samolotem, nurkowania, wycieczek górskich. Nie oczyszczać
                  nosa poprzez wydmuchiwanie, zrezygnować z grania na
                  instrumentach dętych np. flet, klarnet, trąbka, puzon.
                </td>
              </tr>
            </tbody>
          </table>
      </div>
    </Layout>
  );
};
export default Implantologia;
